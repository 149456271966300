// line heights

import { createTheme } from '@mui/material'

const lineHeight = 24

export const labelLineHeight = 1
export const radioButtonOptionsLineHeight = 1.3
export const benefitLineHeight = 1.5
export const listItemsLineHeight = 30

// sizes
const rootFontSize = 16
const h1FontSize = 28
const h2FontSize = 22
const body1FontSize = 16
export const body2FontSize = 18
const subtitle1FontSize = 14

export const disclaimerFontSize = 10
export const showHideButtonFontSize = 12
export const titleFontSize = 15
export const copyFontSize = 20
export const reportValuesFontSize = 24
export const reportBlurFontSize = 25
export const reportValue1FontSize = 30
export const reportValue2FontSize = 34

// weights
const fontWeightLight = 300
const fontWeightRegular = 400
const fontWeightMedium = 500
const fontWeightBold = 700

// colors
const black = '#383838'
const white = '#FFFFFF'
const body = '#7A7A7A'
const primary = '#024AEF'
const secondary = '#65B920'
const lightBlue = '#ECF2F6'

export const answerBorderColor = '1px solid #CADBE5'
export const backgroundColor = '#DDDDDD'
export const errorBackgroundColor = '#FFE8E8'
export const errorBorderColor = '1px solid #AF2113'
export const errorCardBackgroundColor = '#F9EEED'
export const errorColor = '#FF0000'
export const hoverBackgroundColor = 'rgba(121,162,255,1)'
export const inputBackgroundColor = '#F7F7F7'
export const inputBorderColor = '#CADBE5'
export const labelColor = '#777777'
export const promoCodeAlertBackgroundColor = '#E2F4D4'
export const radioButtonUncheckedBorderColor = '2px solid #C4C4C4'
export const successColor = '#5A9A26'
export const stepperBorderColor = '2px solid #93A3B1'
export const stepperColor = '#93A3B1'
export const strikeThroughColor = '#ADBAC4'
export const titleColor = '#666666'
export const uncheckedBoxBorderColor = '1.5px solid #B5B5B5'

// fonts
const isCSIQ = process.env.THEME_NAME === 'CreditScoreIQ'
const isCBIQ = process.env.THEME_NAME === 'CreditBuilderIQ'

export const fontPrimary = 'Raleway'
export const fontSecondary = isCSIQ || isCBIQ ? 'Poppins' : 'Montserrat'
export const fontTitle = 'Raleway'

// box shadows
export const cardBoxShadow = 'none'
export const cardComponentBoxShadow = '0 3px 30px #7A7A7A20'
export const containerBoxShadow = '0 0 15px rgba(101, 185, 33, 0.5)'
export const radioButtonBoxShadow = '0 0 7px rgba(101,185,33,0.4)'
export const submitButtonBoxShadow = '0 0 20px rgba(2, 74, 239, 0.5)'

// borders
export const borderBottom = '1px solid rgba(202, 219, 229, 1)'
export const borderTop = '1px solid rgba(202, 219, 229, 1)'
export const headerBorderBottom = '2px solid rgba(255, 255, 255, 0.1)'

function lines(lines: number): string {
  return `${(lineHeight * lines) / rootFontSize}rem`
}

const theme = createTheme({
  typography: {
    fontFamily: fontPrimary,
    htmlFontSize: rootFontSize,
    fontSize: rootFontSize,
    fontWeightLight,
    fontWeightRegular,
    fontWeightMedium,
    fontWeightBold,
    h1: {
      fontWeight: fontWeightMedium,
      fontFamily: fontTitle,
      fontSize: `${h1FontSize / rootFontSize}rem`,
      lineHeight: lines(2),
      letterSpacing: 0,
    },
    h2: {
      fontWeight: fontWeightMedium,
      fontFamily: fontTitle,
      fontSize: `${h2FontSize / rootFontSize}rem`,
      lineHeight: lines(1),
      letterSpacing: 0,
    },
    body1: {
      fontSize: body1FontSize,
      letterSpacing: 0,
    },
    body2: {
      fontSize: body2FontSize,
      letterSpacing: 0,
    },
    subtitle1: {
      // small text, disclosures
      fontSize: subtitle1FontSize,
    },
  },
  palette: {
    common: {
      black,
      white,
    },
    primary: {
      light: lightBlue,
      main: primary,
      contrastText: white,
    },
    secondary: {
      main: secondary,
    },
    background: {
      paper: '#F7F7F7',
      default: '#F7F7F7',
    },
    text: {
      primary: body,
      secondary: black, // headings
    },
  },
  shape: {
    borderRadius: 4,
  },
  components: {
    MuiButton: {
      styleOverrides: {
        contained: {
          borderRadius: '40px',
        },
      },
    },
    MuiLink: {
      styleOverrides: {
        root: {
          fontWeight: fontWeightMedium,
          fontSize: body1FontSize,
        },
        underlineHover: {
          '&:hover': { textDecoration: 'none', color: '#7A7A7A' },
        },
      },
    },
  },
})

export type Theme = typeof theme
export default theme
