import React, { FunctionComponent, useEffect, useState } from 'react'
import { Theme } from '@/theme'
import PartnerLogo from './PartnerLogo'
import WarningBanner from '../WarningBanner'
import useQueryString from '../../hooks/useQueryString'
import { useSelector } from 'react-redux'
import { selectActivationState, selectEnrollmentState } from '@/state/selectors'
import { EnrollmentStep } from '@/state/types'
import { Box, useTheme } from '@mui/material'

const useStyles = (theme: Theme) => ({
  header: {
    display: 'flex',
    flexDirection: 'row',
    padding: '32px 15px 24px',
    justifyContent: 'center',
    alignItems: 'center',
    maxWidth: '1200px',
    margin: '0 auto',
    [theme.breakpoints.down('sm')]: {
      padding: '32px 0 24px',
    },
    '& .headerLogosContainer': {
      display: 'flex',
      alignItems: 'center',
      '& picture': {
        display: 'flex',
      },
    },
  },
  idiqHeader: {
    alignItems: 'center',
    '& picture img': {
      maxHeight: 'none',
      height: '40px',
      verticalAlign: 'middle',
    },
  },
  msiqHeader: {
    '&.headerLogosContainer img': {
      [theme.breakpoints.down('sm')]: {
        height: '25px',
      },
    },
  },
  csiqHeader: {
    '.headerLogosContainer > img': {
      height: 33.5,
    },
    '.headerLogosContainer picture img, &.cbiqHeader .headerLogosContainer picture img': {
      width: '224px',
      height: 'auto',
    },
    '.hasCobranding picture img': {
      [theme.breakpoints.down('sm')]: {
        width: 'auto',
        height: '45px',
      },
    },
  },
  cbiqHeader: {
    '.headerLogosContainer > img': {
      height: 26,
    },
    '.headerLogosContainer.hasCobranding img': {
      [theme.breakpoints.down('sm')]: {
        width: 'auto',
        height: '31px',
      },
    },
  },
  brandLogo: {
    height: '31px',
  },
})

interface Props {
  businessLogo?: string
  pencilBannerEnabled?: boolean
}

const Header: FunctionComponent<Props> = ({ businessLogo, pencilBannerEnabled }: Props) => {
  const theme = useTheme()
  const classes = useStyles(theme)
  const query = useQueryString()
  const isIdentityIQ = process.env.THEME_NAME === 'IdentityIQ'
  const isMyscoreIQ = process.env.THEME_NAME === 'MyScoreIQ'
  const isCSIQ = process.env.THEME_NAME === 'CreditScoreIQ'
  const { step } = useSelector(selectEnrollmentState)
  const { details: activationDetails } = useSelector(selectActivationState)
  const [isShowBanner, setIsShowBanner] = useState(false)
  const brandHeaderStyles = isIdentityIQ
    ? classes.idiqHeader
    : isMyscoreIQ
    ? classes.msiqHeader
    : isCSIQ
    ? classes.csiqHeader
    : classes.cbiqHeader

  useEffect(() => {
    setIsShowBanner(!query.queryObject?.activationkey && !activationDetails && step === EnrollmentStep.AccountInfo)
  }, [activationDetails, step])

  return (
    <>
      <Box sx={[classes.header, brandHeaderStyles]}>
        <div className={businessLogo ? 'headerLogosContainer hasCobranding' : 'headerLogosContainer'}>
          {isIdentityIQ ? (
            <picture>
              {!!businessLogo && (
                <source width="34" height="40" srcSet="./images/idiq_shield.svg" media="(max-width: 900px)" />
              )}
              <Box
                component={'img'}
                width="204"
                height="40"
                src="./images/idiq_light.svg"
                alt="IdentityIQ"
                sx={classes.brandLogo}
              />
            </picture>
          ) : (
            <picture>
              {!!businessLogo && <source srcSet={process.env.HEADER_LOGO_MOBILE} media="(max-width: 599px)" />}
              <Box
                component={'img'}
                src={process.env.HEADER_LOGO}
                alt={process.env.HEADER_LOGO}
                sx={classes.brandLogo}
              />
            </picture>
          )}
          <PartnerLogo businessLogo={businessLogo}></PartnerLogo>
        </div>
      </Box>
      {isShowBanner && isIdentityIQ && pencilBannerEnabled && <WarningBanner />}
    </>
  )
}

export default Header
