/* eslint-disable react/jsx-no-target-blank */
import React, { FunctionComponent, useState } from 'react'
import { Theme, fontSecondary } from '@/theme'
import Dialog from '../Dialog'
import { REPORT_URL } from '@/constants'
import { qs } from '../../utils/queryString'
import { Box, Button, useTheme } from '@mui/material'

const useStyles = (theme: Theme) => ({
  warningBanner: {
    height: '60px',
    textAlign: 'center',
    padding: '18px',
    color: '#FFF',
    fontSize: '16px',
    fontFamily: fontSecondary,
    position: 'relative',
    background: '#3B4752',
    margin: '13px 0 24px 0',
    '& button': {
      textDecoration: 'underline',
      fontWeight: 600,
      cursor: 'pointer',
      background: 'none',
      border: '0',
      fontFamily: fontSecondary,
      padding: '0',
      color: '#FFF',
      fontSize: 'inherit',
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '12px',
      padding: '4px 26px',
      height: '100%',
      minHeight: '28px',
      maxHeight: '42px',
      margin: '5px -10px 24px -10px',
    },
  },
  bannerClose: {
    position: 'absolute',
    right: { base: '5px', sm: '16px' },
    minWidth: 'auto',
    top: { base: '50%', sm: '20px' },
    marginTop: { base: '-11px', sm: 0 },
    width: '20px',
    height: '20px',
    cursor: 'pointer',
    '&:after': {
      content: '""',
      position: 'absolute',
      left: '2px',
      width: '16px',
      height: '2px',
      background: '#FFF',
      transform: 'rotate(45deg)',
    },
    '&:before': {
      content: '""',
      width: '16px',
      position: 'absolute',
      height: '2px',
      right: '2px',
      background: '#FFF',
      transform: 'rotate(135deg)',
    },
  },
  bannerPopupClose: {
    position: 'absolute',
    right: '22px',
    top: '10px',
    width: '24px',
    height: '24px',
    minWidth: 'auto',
    background: 'none',
    border: '0',
    padding: '0',
    cursor: 'pointer',
    '&:after': {
      content: '""',
      position: 'absolute',
      left: '3px',
      width: '18px',
      height: '2px',
      background: '#3B4752BF',
      transform: 'rotate(45deg)',
    },
    '&:before': {
      content: '""',
      position: 'absolute',
      width: '18px',
      height: '2px',
      right: '3px',
      background: '#3B4752BF',
      transform: 'rotate(135deg)',
    },
  },
  popupLink: {
    fontWeight: 700,
    color: '#3B4752',
  },
  popupLinkButton: {
    color: '#FFF',
    borderRadius: '20px',
    padding: '8px 16px 10px',
    marginTop: '30px',
    textDecoration: 'none',
    fontWeight: 800,
    display: 'inline-block',
    background: theme.palette.primary.main,
    transition: 'opacity 0.2s linear',
    '&:hover': {
      opacity: '0.7',
    },
  },
  warningBannerPopup: {
    padding: '26px 0 10px 0',
    width: '456px',
    color: '#3B4752',
    fontSize: '16px',
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
    borderRadius: '20px',
    [theme.breakpoints.down('sm')]: {
      fontSize: '12px',
    },
  },
  warningBannerRoot: {
    '& .MuiBackdrop-root': {
      background: '#0000003D',
    },
  },
})

const WarningBanner: FunctionComponent = () => {
  const theme = useTheme()
  const classes = useStyles(theme)
  const [isShow, setIsShow] = useState(true)
  const [isShowPopup, setIsShowPopup] = useState(false)
  const queryParams = qs.parse(window.location.search)
  const { transactionid, aff_id, aff_sub, aff_sub2, aff_sub3, offercode } = queryParams
  const query = qs.stringify({
    transactionid,
    aff_id,
    aff_sub,
    aff_sub2,
    aff_sub3,
    offercode,
  })

  return (
    <>
      {isShow && (
        <Box sx={classes.warningBanner}>
          Referred for rental or employment verification?{' '}
          <Button sx={{ textTransform: 'none' }} variant="text" type="button" onClick={() => setIsShowPopup(true)}>
            Click Here
          </Button>
          .<Button type="button" sx={classes.bannerClose} onClick={() => setIsShow(false)}></Button>
        </Box>
      )}
      {isShowPopup && (
        <Dialog
          open={isShowPopup}
          onClose={() => setIsShowPopup(false)}
          sx={{ paper: classes.warningBannerPopup, root: classes.warningBannerRoot }}
        >
          <Button type="button" sx={classes.bannerPopupClose} onClick={() => setIsShowPopup(false)}></Button>
          <Box>
            IDIQ does not advertise or promote our services on Craigslist, Indeed, Zillow or similar sites that are
            requesting credit information to verify employment or qualifying for a new rental agreement. We do accept
            referrals from personal loan sites to provide credit reporting and scores, but enrolling is not a
            requirement or condition of loan approval. If you are enrolling in IdentityIQ services because you were told
            to do so for verification of employment, rental agreement qualification or something similar, it is
            potentially a scam. Please report it{' '}
            <a href={`${REPORT_URL}?${query}`} target="_blank" style={classes.popupLink}>
              here
            </a>
            . We are aware of advertisers that are not legitimate and may be trying to obtain your credit information
            and identity for unlawful purposes. IdentityIQ provides identity theft protection and credit monitoring
            services for consumers looking for peace of mind with 24/7 protection of your personal information.
          </Box>
          <a href={`${REPORT_URL}?${query}`} target="_blank" style={classes.popupLinkButton}>
            Report Here
          </a>
        </Dialog>
      )}
    </>
  )
}

export default WarningBanner
