import { SxProps, Theme, Typography } from '@mui/material'
import React, { FunctionComponent, ReactNode } from 'react'

interface Props {
  children: ReactNode
  sx?: SxProps<Theme>
}

export const H1: FunctionComponent<Props> = ({ children, sx }: Props) => {
  return (
    <Typography variant="h1" color="textSecondary" sx={sx}>
      {children}
    </Typography>
  )
}

export const H2: FunctionComponent<Props> = ({ children, sx }: Props) => {
  return (
    <Typography variant="h2" color="textSecondary" sx={sx}>
      {children}
    </Typography>
  )
}
