import React, { FunctionComponent, ReactNode } from 'react'
import { DialogContent, Dialog as Modal, SxProps, Theme, useTheme } from '@mui/material'

const smPadding = 3
const mdPadding = 6

interface Props {
  sx?: {
    paper?: SxProps<Theme>
    root?: SxProps<Theme>
  }
  className?: string
  children: ReactNode
  open: boolean
  onClose?: () => void
}

const useStyles = (theme: Theme) => ({
  content: {
    overflow: 'hidden',
  },
  root: {
    [theme.breakpoints.down('sm')]: {
      padding: smPadding,
      overflowY: 'inherit',
    },
    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(mdPadding),
      overflowY: 'inherit',
    },
  },
})

const Dialog: FunctionComponent<Props> = ({ sx, open, onClose, children }: Props) => {
  const theme = useTheme()
  const { root, content } = useStyles(theme)

  return (
    <>
      <Modal
        open={open}
        sx={{ root, ...(sx?.root || {}) }}
        onClose={onClose}
        slotProps={{
          paper: { sx: sx?.paper },
        }}
      >
        <DialogContent sx={content}>{children}</DialogContent>
      </Modal>
    </>
  )
}

export default Dialog
