import React, { FunctionComponent } from 'react'
import { PartnerLogoBaseUrl } from '@/constants'
import { Box } from '@mui/material'

const useStyles = () => ({
  logo: {
    display: 'inline-flex',
    height: '31px',
    marginLeft: '36px',
  },
  qvcardLogo: {
    display: 'inline-flex',
    height: '40px',
    marginLeft: '36px',
  },
})

interface Props {
  businessLogo?: string
}

const PartnerLogo: FunctionComponent<Props> = ({ businessLogo }: Props) => {
  const classes = useStyles()
  const useNewQvcardStyles = process.env.THEME_NAME === 'IdentityIQ'

  return businessLogo ? (
    <Box
      component={'img'}
      sx={useNewQvcardStyles ? classes.qvcardLogo : classes.logo}
      src={`${PartnerLogoBaseUrl}${businessLogo}`}
      alt={`${PartnerLogoBaseUrl}${businessLogo}`}
    />
  ) : null
}

export default PartnerLogo
